import styled from "styled-components"

import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function HomeLoadingState() {
  return (
    <HomeLoadingStateBox>
      <MSkeleton />
      <MSkeleton />
    </HomeLoadingStateBox>
  )
}

const HomeLoadingStateBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`
